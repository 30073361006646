import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

const Content = ({ title, content }) => (
  <section className="pt-20">
    <Container>
      <Heading tag="h1" size="xl" theme="primary-black" className="text-center">
        {title}
      </Heading>
      <div className="prose prose-lg" dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  </section>
);

Content.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Content;
