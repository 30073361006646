/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';

import Content from 'components/shared/content';
import CTA from 'components/shared/cta';
import { defaultCTA } from 'constants/component-data';
import MainLayout from 'layouts/main';

const StaticPage = ({
  data: {
    wpPage: { title, content, seo },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext} headerTheme="primary-white">
    <Content title={title} content={content} />
    <CTA {...defaultCTA} marginTop="lg" />
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      staticPageFields {
        cta {
          title
          buttonText
          buttonUrl
        }
      }
      ...wpPageSeo
    }
  }
`;

export default StaticPage;
